import { reactLocalStorage } from 'reactjs-localstorage';
import Resizer from "react-image-file-resizer";
import config from './config';

var DURATION_IN_SECONDS = {
  epochs: ['year', 'month', 'day', 'hour', 'minute'],
  year: 31536000,
  month: 2592000,
  day: 86400,
  hour: 3600,
  minute: 60,
};

const getBase64FromUrl = async (url) => {
  const data = await fetch(url);
  const blob = await data.blob();
  return new Promise((resolve) => {
    const reader = new FileReader();
    reader.readAsDataURL(blob);
    reader.onloadend = () => {
      const base64data = reader.result;
      resolve(base64data);
    }
  });
}

const common = {
  getBase64FromUrl,
  productionMode() {
    var oldConsoleLog = null;
    if (config.production_mode) {
      oldConsoleLog = console.log;
      window['console']['log'] = function () { };
    } else {
      if (oldConsoleLog == null)
        return;

      window['console']['log'] = oldConsoleLog;
    }
  },
  isObjectEmpty(obj) {
    for (var prop in obj) {
      if (obj.hasOwnProperty(prop))
        return false;
    }
    return true;
  },
  textRegex(val) {
    const re = /^[A-Za-z ]+$/;
    return re.test(val);
  },
  nikRegex(nik) {
    const re = /^[0-9]*$/;
    return re.test(nik);
  },
  emailRegex(email) {
    const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
  },
  phoneRegex(phone) {
    const re = /^08[0-9]{8,}$/;
    return re.test(phone);
  },
  passwordRegex(pass) {
    const re = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{8,}$/;
    return re.test(pass);
  },
  countDays(date1, date2) {

    if (date1 == date2) {
      return '1 Day';
    } else {
      const oneDay = 24 * 60 * 60 * 1000; // hours*minutes*seconds*milliseconds
      const firstDate = new Date(date1);
      const secondDate = new Date(date2);
      const diffDays = Math.round(Math.abs((firstDate - secondDate) / oneDay));

      if (diffDays > 1) {
        return diffDays + ' Days';
      } else {
        return diffDays + ' Day';
      }
    }

  },
  countHours(date, time1, time2) {
    let times1 = date + ' ' + time1;
    let times2 = date + ' ' + time2;
    var a = new Date(times1);
    var b = new Date(times2);
    var hours = Math.abs(b - a) / 36e5;
    if (hours > 1) {
      let counts = parseFloat(hours.toFixed(1));
      return counts + ' Hour';
    } else {
      return hours + ' Hour';
    }
  },
  formatRupiah(angka, prefix) {
    let angkar = angka.split('.');
    let angkas = angkar[0];
    var number_string = angkas.replace(/[^,\d]/g, '').toString(),
      split = number_string.split(','),
      sisa = split[0].length % 3,
      rupiah = split[0].substr(0, sisa),
      ribuan = split[0].substr(sisa).match(/\d{3}/gi);

    // tambahkan titik jika yang di input sudah menjadi angka ribuan
    let separator = '';
    if (ribuan) {
      separator = sisa ? '.' : '';
      rupiah += separator + ribuan.join('.');
    }

    rupiah = split[1] != undefined ? 'Rp ' + rupiah + ',' + split[1] : rupiah;
    return prefix == undefined ? 'Rp ' + rupiah : (rupiah ? 'Rp ' + rupiah + '.00' : '');

    return angka;
  },
  formatRupiahAngka(angka, prefix) {
    let angkar = angka.split('.');
    let angkas = angkar[0];
    var number_string = angkas.replace(/[^,\d]/g, '').toString(),
      split = number_string.split(','),
      sisa = split[0].length % 3,
      rupiah = split[0].substr(0, sisa),
      ribuan = split[0].substr(sisa).match(/\d{3}/gi);

    // tambahkan titik jika yang di input sudah menjadi angka ribuan
    let separator = '';
    if (ribuan) {
      separator = sisa ? '.' : '';
      rupiah += separator + ribuan.join('.');
    }

    rupiah = split[1] != undefined ? '' + rupiah + ',' + split[1] : rupiah;
    return prefix == undefined ? '' + rupiah : (rupiah ? '' + rupiah + '.00' : '');

    return angka;
  },
  dateFormat(date) {
    let dates = new Date(date);
    var days = ['Sun', 'Mon', 'Tues', 'Wed', 'Thur', 'Fri', 'Sat'];
    var months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Des'];
    var d = new Date(dates);
    let format = days[d.getDay()] + ', ' + dates.getDate() + ' ' + months[d.getMonth()] + ' ' + dates.getFullYear();
    return format;
  },
  dateTimeFormat(date) {
    let dates = new Date(date);
    var days = ['Sun', 'Mon', 'Tues', 'Wed', 'Thur', 'Fri', 'Sat'];
    var months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Des'];
    var d = new Date(dates);
    let format = dates.getDate() + ' ' + months[d.getMonth()] + ' ' + dates.getFullYear() + ' ' + dates.getHours() + ':' + dates.getMinutes();
    return format;
  },
  findDays(from, to) {
    var d = new Date(from),
      a = [],
      y = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
    while (d < to) {
      a.push(y[d.getDay()]);
      d.setDate(d.getDate() + 1);
    }
    if (d.getDay() === to.getDay()) // include last day
      a.push(y[d.getDay()]);
    return a;
  },
  setToSecureStorage(key, object) {
    return new Promise((resolve, reject) => {
      reactLocalStorage.set(key, object).then((res) => {
        resolve(res);
      }, (err) => {
        reject(err);
      });

    })
  },
  getFromSecureStorage(key) {
    return new Promise((resolve, reject) => {
      reactLocalStorage.get(key)
        .then((res) => {
          resolve(res);
        }, (err) => {
          reject(err);
        });

    })
  },
  toFindDuplicates(arry) {
    const uniqueElements = new Set(arry);
    const filteredElements = arry.filter(item => {
      
        return item;
      
    });

    return [...new Set(uniqueElements)]
  },
  removeFromSecureStorage(key) {
    return new Promise((resolve, reject) => {
      reactLocalStorage.remove(key)
        .then((res) => {
          // console.log("RNSecureKeyStore get success : ", res);
          resolve(res);
        }, (err) => {
          // console.log("RNSecureKeyStore get err : ", err);
          reject(err);
        });
    })
  },


  getDuration(seconds) {
    var epoch, interval;

    for (var i = 0; i < DURATION_IN_SECONDS.epochs.length; i++) {
      epoch = DURATION_IN_SECONDS.epochs[i];
      interval = Math.floor(seconds / DURATION_IN_SECONDS[epoch]);
      if (interval >= 1) {
        return {
          interval: interval,
          epoch: epoch
        };
      }
    }

  },
  timeSince(date) {
    var seconds = Math.floor((new Date() - new Date(date)) / 1000);
    var duration = this.getDuration(seconds);
    var suffix = 0;
    if (duration) {
      suffix = (duration.interval > 1 || duration.interval === 0) ? 's' : '';
      return duration.interval + ' ' + duration.epoch + suffix + ' ago';
    } else {
      return 'Just now';
    }
  },
  // convertBase64(file) {
  //   return new Promise((resolve, reject) => {
  //     const fileReader = new FileReader();
  //     fileReader.readAsDataURL(file)
  //     fileReader.onload = () => {
  //       resolve(fileReader.result);
  //     }
  //     fileReader.onerror = (error) => {
  //       reject(error);
  //     }
  //   })
  // },

  convertBase64(file) {
    return new Promise((resolve, reject) => {
      var fileInput = false;
      if (file) {
        fileInput = true;
      }
      if (fileInput) {
        try {
          Resizer.imageFileResizer(
            file, 500, 500, "JPEG", 100, 0,
            (uri) => {
              resolve(uri);
            }, "base64", 300, 300
          );
        } catch (err) {
          reject(err);
        }
      }
    })
  },

  convertBase64_2(file) {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file)
      fileReader.onload = () => {
        resolve(fileReader.result);
      }
      fileReader.onerror = (error) => {
        reject(error);
      }
    })
  },
}

export default common;
